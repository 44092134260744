import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import Index from 'component/index';
import style from './style.module.scss';
import Tags from 'component/tags';
import { humanizeSlug } from 'utils/uri';

function TagDetail() {
  const params = useParams<{ slug: string }>();
  const [index, setIndex] = useState([]);
  const [tags, setTags] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    import(`../../data/tags.json`).then(result => {
      const tags = result.default as any || [];
      const tagSelection = _.shuffle(tags).slice(0, 100);
      setTags(tagSelection as any);
    });
  }, []);
  useEffect(() => {
    setIndex([]);
    import(`../../data/tag_${params.slug}_0.json`).then(result => {
      setIndex(result.default as any);
    });
  }, [params.slug]);

  return (
    <div className={style.container}>
      <Tags
        tags={[{id: -1, name: humanizeSlug(params.slug || '')}]}
        title={'Tag:'}
        classes={{ container: style.tagsMain }}
      />
      <Index index={index} />
      <Tags tags={tags} classes={{ container: style.tags }} />
    </div>
  );
}

export default TagDetail;
