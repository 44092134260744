import { Helmet } from 'react-helmet';

function Meta() {
  return (
    <Helmet>
      <title>Desire to Fuck | Porn forever</title>
    </Helmet>
  );
}

export default Meta;
