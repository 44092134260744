import classNames from 'classnames';
import style from './style.module.scss';

interface RefreshProps {
  classes?: { container?: string };
}
const Refresh = ({ classes }: RefreshProps) => {
  return (
    <div
      className={classNames(style.container, classes?.container)}
      onClick={() => {
        window.scrollTo(0, 0);
        window.location.reload();
      }}
    >
      <div className={style.text}>Refresh!</div>
    </div>
  );
};

export default Refresh;
