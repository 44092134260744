import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useNavigate, generatePath } from 'react-router-dom';
import { ROUTE_DETAIL } from 'routing/routes';
import style from './style.module.scss';
import { getAnimatedThumbnailUrl, getStaticThumbnailUrl } from 'utils/stream';

interface IndexDetailProps {
  detail: {[key: string]: any};
  onClick?: ({detail }: {detail: any}) => any;
  classes?: { container?: string };
}
const IndexDetail = ({
  detail,
  detail: {
    id,
    name,
    slug,
    uid,
    thumbnail,
    thumbnail_timestamp,
    preview_duration,
    categories,
    tags,
  },
  onClick,
  classes
}: IndexDetailProps) => {
  const navigate = useNavigate();
  const [thumbnailUrl, setThumbnailUrl] = useState(getStaticThumbnailUrl({ uid, timestamp: thumbnail_timestamp }));
  useEffect(() => {
    setThumbnailUrl(getStaticThumbnailUrl({ uid, timestamp: thumbnail_timestamp }));
  }, [uid]);

  return (
    <div
      className={classNames(style.container, classes?.container)}
      // style={{ backgroundImage: `url(${thumbnail})` }}
      onClick={() => {
        navigate(generatePath(ROUTE_DETAIL, { slug }));
        onClick?.({detail});
      }}
      onMouseEnter={() => setThumbnailUrl(getAnimatedThumbnailUrl({ uid, timestamp: thumbnail_timestamp, duration: preview_duration }))}
      onMouseLeave={() => setThumbnailUrl(getStaticThumbnailUrl({ uid, timestamp: thumbnail_timestamp }))}
    >
      <div className={style.mobileTitle}>{name}</div>
      <img alt="" src={thumbnailUrl} />
      <div className={style.titleOverlay}>
        <div className={style.content}>
          {name}
        </div>
      </div>
    </div>
  );
};

export default IndexDetail;
