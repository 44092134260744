import { text } from "stream/consumers";

export const convertToSlug = (text: string) => {
  return text.toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
};

export const humanizeSlug = (slug: string) => {
  return slug.replace(/-/g, ' ').replace(/[ ]+/, ' ');
};
