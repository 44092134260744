import { Routes, Route } from 'react-router-dom';
import { ROUTE_DETAIL, ROUTE_TAG_DETAIL } from 'routing/routes';
import Layout from '../layout';
import Home from '../home';
import Detail from '../detail';
import TagDetail from '../tag-detail';
import Demo from '../demo';
import NotFound from '../not-found';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path={ROUTE_DETAIL} element={<Detail />} />
        <Route path={ROUTE_TAG_DETAIL} element={<TagDetail />} />
        <Route path="demo" element={<Demo />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
