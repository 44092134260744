import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import IndexDetail from 'component/index-detail';
import { useDataFilePaginator } from 'utils/hook';
import style from './style.module.scss';
import Tags from 'component/tags';
import { getIframeStreamUrl, getStaticThumbnailUrl } from 'utils/stream';

interface VideoDetail {
  id: number;
  name: string;
  slug: string;
  uid: string;
  thumbnail: string;
  thumbnail_timestamp: number;
  preview_duration: number;
  categories: Array<{id: number; name: string}>;
  tags: Array<{id: number; name: string}>;
}

function Detail() {
  const params = useParams<{ slug: string }>();
  const [tags, setTags] = useState([]);
  const [detail, setDetail] = useState<VideoDetail>({
    id: 0,
    name: '',
    slug: '',
    uid: '',
    thumbnail: '',
    thumbnail_timestamp: 0,
    preview_duration: 5,
    categories: [],
    tags: []
  });
  const [relatedIndex, setRelatedIndex] = useState<Array<VideoDetail>>([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    import(`../../data/tags.json`).then(result => {
      const tags = result.default as any || [];
      const tagSelection = _.shuffle(tags).slice(0, 100);
      setTags(tagSelection as any);
    });
  }, []);
  useEffect(() => {
    import(`../../data/detail_${params.slug}.json`).then(result => {
      setDetail(result.default);
    });
  }, [params]);
  const category = detail?.categories?.[0];
  useDataFilePaginator({
    getImport: index => import(`../../data/category_${category?.name}_${index}.json`),
    getPages: ({ pages, pageIds }) => {
      const randomPage = pageIds.length ? pages[_.sample(pageIds) as any] : null;
      if (randomPage && detail.id) {
        const filteredPage = randomPage.filter((video: any) => video.id !== detail.id);
        setRelatedIndex(filteredPage);
      }
    },
    dependency: [category?.name, detail]
  });

  return (
    <div className={style.container}>
      <div className={style.detail}>
        <div className={style.title}>{detail.name}</div>
        <div className={style.video}>
          <iframe
            title="_"
            src={getIframeStreamUrl({ detail })}
            style={{
              border: 'none',
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%'
            }}
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
            allowFullScreen
          />
        </div>
        <Tags tags={detail.tags} classes={{ container: style.tags }} />
      </div>
      {!!relatedIndex.length && (
        <div className={style.relatedIndex}>
          {relatedIndex.map((detail, i) => (
            <IndexDetail
              key={i}
              detail={detail}
              onClick={() => window.scrollTo(0, 0)}
              classes={{ container: style.detailView }}
            />
          ))}
        </div>
      )}
      <Tags tags={tags} classes={{ container: style.tags }} />
    </div>
  );
}

export default Detail;
