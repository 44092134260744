import classNames from 'classnames';
import style from './style.module.scss';

interface FooterProps {
  classes?: { container?: string };
}
const Footer = ({ classes }: FooterProps) => {
  return (
    <div className={classNames(style.container, classes?.container)}>
      <div className={style.links}>
        <a href={'/'}>Tags</a> - <a rel='noreferrer' target={'_blank'} href={'https://theporndude.com/'}>Best Porn Sites</a> - <a href={`mailto: d2fcontacts@gmail.com`}>Content Removal (DMCA)</a>
      </div>
      <div className={style.notice}>
        DESIRETOFUCK.COM does not encourage/condone illegal sexual conduct and is intended solely to provide visual pleasure for ADULTS only. <span>Please leave this site if you are under 18</span> or if you find mature/explicit content offensive. All mature/explicit content on this site is strictly in compliance with 18 U.S. § Code 2257 i.e. <span>all performers featured are 18 years or older of age</span>. DESIRETOFUCK.COM and all its associated domains being in accordance with 17 U.S.C. § 512 and the Digital Millennium Copyright Act, responds to infringement notices as soon as possible.
      </div>
    </div>
  );
};

export default Footer;
