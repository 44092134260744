import { useEffect } from 'react';

interface UseDataFilePaginatorParams {
  getImport: (index: number) => any;
  getPages?: ({ pages }: { pages: {[key: string]: any}, pageIds: Array<Number> }) => any;
  getError?: (e: any) => any;
  dependency?: Array<any>;
}
export const useDataFilePaginator = ({
  getImport,
  getPages,
  getError,
  dependency = []
}: UseDataFilePaginatorParams) => {
  useEffect(() => {
    if (dependency.every((nth: any) => nth)) {
      const getModule = async (index: any) => {
        try {
          const _import = getImport(index);
          return _import ? await _import : null;
        } catch(e) {
          return null;
        }
      };

      const _getPages = async () => {
        let index = 0;
        let pages = {};

        while(true) {
          let result = await getModule(index);
          if (result) {
            Object.assign(pages, {[index + 1]: (result as any).default});
            index += 1;
          } else {
            break;
          }
        }

        return pages;
      };

      _getPages()
        .then(pages => {
          getPages?.({ pages, pageIds: Object.keys(pages).map(id => Number(id)).sort() });
        })
        .catch(e => {
          getError?.(e);
        });
    }
  }, dependency);
};
