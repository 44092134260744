import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { ROUTE_ROOT } from 'routing/routes';
import logo from './d2f_logo.png';
import style from './style.module.scss';

interface HeaderProps {
  classes?: { container?: string };
}
const Header = ({ classes }: HeaderProps) => {
  const navigate = useNavigate();

  return (
    <div className={classNames(style.container, classes?.container)}>
      <div className={style.logo} onClick={() => navigate(ROUTE_ROOT)}>
        <img alt="" src={logo} />
        <div className={style.subtitle}>porno pour toujours</div>
      </div>
    </div>
  );
};

export default Header;
