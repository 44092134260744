import { Video } from 'types/data';
import { getDurationString } from './date-time';

export const getIframeStreamUrl = ({
  detail
}: { detail: Video }) => {
  const staticThumbnailUrl = getStaticThumbnailUrl({ uid: detail.uid, timestamp: detail.thumbnail_timestamp });
  const encodedStaticThumbnailUrl = encodeURIComponent(staticThumbnailUrl);

  return `https://iframe.videodelivery.net/${detail.uid}?poster=${encodedStaticThumbnailUrl}`;
};

export const getStaticThumbnailUrl = ({
  uid,
  timestamp
}: { uid: string; timestamp: number }) =>
  `https://videodelivery.net/${uid}/thumbnails/thumbnail.jpg?time=${getDurationString(timestamp)}`;

  export const getAnimatedThumbnailUrl = ({
    uid,
    timestamp,
    duration
  }: { uid: string; timestamp: number, duration: number }) =>
  `https://videodelivery.net/${uid}/thumbnails/thumbnail.gif?time=${getDurationString(timestamp)}&duration=${getDurationString(duration)}`;
