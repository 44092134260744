import classNames from 'classnames';
import IndexDetail from '../index-detail';
import style from './style.module.scss';

interface IndexProps {
  index: Array<{[key: string]: any}>;
  classes?: { container?: string };
}
const Index = ({ index, classes }: IndexProps) => {
  return index.length ? (
    <div className={classNames(style.container, classes?.container)}>
      {index.map((detail, i) => (
        <IndexDetail
          key={i}
          detail={detail}
          classes={{ container: style.detail }}
        />
      ))}
    </div>
  ) : null;
};

export default Index;
