import { Outlet } from "react-router-dom";
import Header from "component/header";
import Footer from "component/footer";
import style from './style.module.scss';

function Layout() {
  return (
    <div className={style.container}>
      <Header />
      <div className={style.body}>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default Layout;
