import classNames from 'classnames';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTE_TAG_DETAIL } from 'routing/routes';
import { convertToSlug } from 'utils/uri';
import style from './style.module.scss';

interface Tag {
  id: number;
  name: string;
}
interface TagsProps {
  tags: Array<Tag>;
  title?: string;
  classes?: { container?: string };
}
const Tags = ({ classes, tags, title }: TagsProps) => {
  const navigate = useNavigate();

  return tags.length ? (
    <div className={classNames(style.container, classes?.container)}>
      <div className={classNames(style.tag, style.tagName)}>{title || 'Tags:'}</div>
      {tags.map(tag => (
        <div
          key={tag.id}
          className={classNames(style.tag, style.tagValue)}
          onClick={() => {
            navigate(generatePath(ROUTE_TAG_DETAIL, { slug: convertToSlug(tag.name) }));
            window.scrollTo(0, 0);
          }}
        >
          {tag.name}
        </div>
      ))}
    </div>
  ) : null;
};

export default Tags;
