import { useState, useEffect } from 'react';
import _ from 'lodash';
import Index from 'component/index';
import style from './style.module.scss';
import Refresh from 'component/refresh';
import Tags from 'component/tags';

function Home() {
  const [index, setIndex] = useState([]);
  const [tags, setTags] = useState([]);
  useEffect(() => {
    import(`../../data/home_${_.sample(_.range(10))}.json`).then(result => {
      setIndex(result.default as any);
    });
    import(`../../data/tags.json`).then(result => {
      const tags = result.default as any || [];
      const tagSelection = _.shuffle(tags).slice(0, 100);
      setTags(tagSelection as any);
    });
  }, []);

  return (
    <div className={style.container}>
      <Index index={index} />
      <Refresh classes={{ container: style.refresh }} />
      <Tags tags={tags} classes={{ container: style.tags }} />
    </div>
  );
}

export default Home;
